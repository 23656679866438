import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirector = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const savedStep = localStorage.getItem('currentStep');
    if (savedStep) {
      navigate(`/quiz/${savedStep}`);
    } else {
      navigate(`/quiz/1`);
    }
  }, [navigate]);

  return null; // This component doesn't render anything
};

export default Redirector;