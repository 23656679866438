import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const StepWrapper = ({ stepId, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('currentStep', stepId.toString());
  }, [stepId]);

  const handleNext = () => {
    const nextStep = stepId + 1;
    navigate(`/quiz/${nextStep}${location.search}`);
  };

  const handleBack = () => {
    const previousStep = stepId - 1;
    navigate(`/quiz/${previousStep}${location.search}`);
  };

  return React.cloneElement(children, { handleNext, handleBack });
};

export default StepWrapper;