import React, { createContext, useContext, useState } from 'react';

// Create a context
const QuizContext = createContext();

// Create a provider component
export const QuizProvider = ({ children }) => {
  const [quizData, setQuizData] = useState({
    gender: '',
    currentBodyType: '',
    targetBodyType: '',
    heightInCm: '',
    weightInKg: '',
    bmi: '',
    perfectWeight: '',
    age: '',
  });

  const updateQuizData = (newData) => {
    setQuizData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <QuizContext.Provider value={{ quizData, updateQuizData }}>
      {children}
    </QuizContext.Provider>
  );
};

// Create a custom hook to use the QuizContext
export const useQuiz = () => useContext(QuizContext);
